<template>
  <v-container v-if="!fetchinData">
    <template v-if="hasAddon()">
      <PageHeader
        :pageTitle="pageTitle"
        :pageDescription="$t('pageDescriptions.addonsShops')"
      />

      <DataTable
        :headers="headers"
        :items="leads"
        :preloader="fetchingData"
        :edit="true"
        :trash="false"
        @tableEvent="emittedTableEvent"
      ></DataTable>
    </template>

    <template v-else>
      <PageHeader
        :pageTitle="pageTitle"
        :pageDescription="$t('pageDescriptions.addonsShops')"
      />
      <p>
        Das Modul ist nicht verfügbar.<br />Bei Fragen oder Interesse
        kontaktiere bitte deinen FACEFORCE Berater.
      </p>

      <MarketingFallback
        :buyAction="true"
        title="mitfitSHOPS"
        content="mitfitSHOPS kann sowohl als Integration auf deiner existierenden Studio-Website als auch in deinen Onlinekampagnen erfolgreich eingesetzt werden. Konvertiere Interessenten online zu zahlenden Kunden, schnell und ohne kostenintensiven Vertriebsaufwand."
        url="https://www.face-force.de/online-marketing/mitfitpay-online-verkauf/"
      />
    </template>

    <v-dialog
      v-model="dialog"
      max-width="1500px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title>Verkauf</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container style="max-width: 1500px; min-height: 90vh" fluid>
            <LeadDetailView
              ref="lead_detail"
              :leadId="leadId"
              :key="componentKey"
              type="shop"
            ></LeadDetailView>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import PageHeader from "@/components/ui/PageHeader";
import MarketingFallback from "@/components/addons//MarketingFallback";
import DataTable from "@/components/ui/DataTable";
import LeadDetailView from "@/views/leads/LeadDetailView";
export default {
  name: "AddonShops",

  components: {
    PageHeader,
    MarketingFallback,
    DataTable,
    LeadDetailView,
  },

  data() {
    return {
      pageTitle: "mitfitSHOPS",
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      addons: [], // wrapper for fetched data
      tab: null,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      data: [], // wrapper for fetched data
      leads: [], // wrapper for fetched data
      leadsStatsByGender: [],
      leadsStatsByYearMonth: [],
      leadsStatsByPaymentStatus: [],
      leadId: null,
      leadData: [],
      campaignData: [],
      headers: [],
      dialog: false,
      itemData: [],
      itemId: null,
      itemIndex: null,
      memberCount: 0,
      componentKey: 0,
    };
  },

  created: function () {
    // allow to change company?
    this.$store.commit("companyChangeOption/SET_ALLOWED", true);

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getAddons();
      }
    );
    this.getAddons();
    this.getData();
  },

  methods: {
    hasAddon() {
      // check if addon id 6 is available in this.addons
      let addon = this.addons.find((addon) => addon.id == "7");
      if (addon) {
        return true;
      } else {
        return false;
      }
    },

    async getAddons() {
      this.fetchinData = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/addons`
      );
      this.addons = response.data.data;
      this.fetchinData = false;
    },

    emittedTableEvent(obj) {
      this.leadId = obj.itemId;
      this.dialog = true;
      this.componentKey += 1;
    },

    async getData() {
      /* fetch data from api
      companies/1/member-leads */
      this.fetchingData = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/shopLeads`
      );
      this.fetchingData = false;
      this.data = response.data.data;
      this.leads = response.data.data;
      this.memberCount = response.data.data.length; // simple count of leads

      this.headers = [
        { text: "", value: "pay.isTerminated" },
        {
          text: this.$t("status"),
          value: "pay.paymentStatus",
        },
        { text: this.$t("date"), value: "created" },
        { text: this.$t("firstName"), value: "firstName" },
        { text: this.$t("lastName"), value: "lastName" },
        { text: this.$t("email"), value: "email" },
        { text: this.$t("products"), value: "pay.items" },
        { text: "", value: "actions", sortable: false },
      ];
    },
  },

  mixins: [manageApi],
};
</script>
