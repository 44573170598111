<template>
  <v-container>
    <v-card>
      <v-toolbar dark color="green">
        <v-toolbar-title>{{
          buyAction ? "JETZT DAZU BUCHEN" : "FACEFORCE-TIPP"
        }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <h2 class="my-2">{{ title }}</h2>

        <div v-html="content"></div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="url"
          large
          :href="url"
          dark
          target="_blank"
          color="primary"
        >
          {{ $t("getMore") }}
          <v-icon right dark>mdi-arrow-top-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)

export default {
  name: "MarketingFallback",

  data() {
    return {};
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      default: null,
    },
    buyAction: {
      type: Boolean,
      default: false,
    },
  },

  created: function () {
    // allow to change company?
    this.$store.commit("companyChangeOption/SET_ALLOWED", true);

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        //this.getData();
        this.getAddons();
      }
    );

    //this.getData();
    this.getAddons();
  },

  methods: {
    clientBlockHack() {
      /* Auf Wunsch wird die Ausgabe für bestimmt Kunden blockiert.
      Das Feature gilt so lange, bis das Manage MEMBER-Addon fertiggestellt wurde. */
      if (
        this.$store.getters["auth/current_sid"] == 480 ||
        this.$store.getters["auth/current_sid"] == 384
      ) {
        return false;
      } else {
        return true;
      }
    },

    hasAddon() {
      // check if addon id 6 is available in this.addons
      let addon = this.addons.find((addon) => addon.id == "6");
      if (addon) {
        return true;
      } else {
        return false;
      }
    },

    async getAddons() {
      this.fetchinData = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/addons`
      );
      this.addons = response.data.data;
      this.fetchinData = false;
    },

    async getData() {
      // ! vorerst deaktiviert, da das dem Anschein nach nicht mehr benötigt wird
      /* fetch data from api */
      this.fetchinData = true;
      let response = await this.getRequest("company/" + this.companyID); // await data from mixin
      this.fetchinData = false;
      this.data = response.data.status;
    },
  },

  computed: {
    //
  },

  /* all requests by mixin */
  mixins: [manageApi],
};
</script>
